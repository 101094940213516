import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Біз туралы | The Whistle & Keg Tavern туралы
			</title>
			<meta name={"description"} content={"The Whistle & Keg Tavern-де біздің тарихымыз тарих жылнамасында емес, күнделікті қуаныш пен жолдастық сәттерде жазылған."} />
			<meta property={"og:title"} content={"Біз туралы | The Whistle & Keg Tavern туралы"} />
			<meta property={"og:description"} content={"The Whistle & Keg Tavern-де біздің тарихымыз тарих жылнамасында емес, күнделікті қуаныш пен жолдастық сәттерде жазылған."} />
			<meta property={"og:image"} content={"https://indargimakz.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://indargimakz.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://indargimakz.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://indargimakz.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://indargimakz.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://indargimakz.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://indargimakz.com/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://indargimakz.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://indargimakz.com/img/6.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="400px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Біз туралы
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Сыбызғы мен Кегтің мәні
				</Text>
				<Text font="--base">
					The Whistle & Keg Tavern-де біздің тарихымыз тарих жылнамасында емес, күнделікті қуаныш пен жолдастық сәттерде жазылған. Біздің паб - бұл жақсы тамақ, керемет сусындар және одан да жақсы компанияға жиналудың ескірмейтін дәстүрінің мерекесі.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://indargimakz.com/img/8.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://indargimakz.com/img/3.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					Мұнда The Whistle & Keg-де біз классикалық паб рухын құрметтейміз және оны заманауи талғаммен толтырамыз. Сүйіспеншілікпен жасалған интерьерлерден бастап мұқият таңдалған мәзірге дейін біздің тавернаның барлық аспектілері жайлы әрі қызықты тәжірибе жасауға арналған.
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://indargimakz.com/img/4.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Whistle & Keg тек тамақтан немесе сусыннан ләззат алу ғана емес, естеліктер жасау. Достармен түнде демалу, барда жұбату кеші немесе мерекелік іс-шара болсын, біздің есігіміз сізді жылы, тартымды атмосферада қарсы алу үшін ашық.
				</Text>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://indargimakz.com/img/7.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					Біздің кеңістігіміз әңгімелесу мен байланысты ынталандыруға арналған. Жаңа достыққа шақыратын ортақ үстелдерден бастап, интимдік пікірталастарға арналған тыныш бұрыштарға дейін, The Whistle & Keg - бұл әңгімелер бөлісетін және жаңалары жазылатын орын.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
						Әр бұрыштағы әңгіме
						<br />
						<br />
						Біз тарихты өткенімізбен мақтана алмасақ та, біз бүгінгі күннің мұраны құра алатын күшіне сенеміз. Бөліскен әрбір күлкі, айтылған әрбір ән және көтерілген әрбір стақан The Whistle & Keg оқиғасының жалғасуына ықпал етеді.
					</Text>
					<Button href="/contacts" type="link" text-decoration-line="initial" color="--dark">
						Контактілер
					</Button>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://indargimakz.com/img/2.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});